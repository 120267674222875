import { useRouter, useSearchParams } from 'next/navigation';
import qs from 'qs';
import { useMemo, useState } from 'react';
import useSWR from 'swr';
import urlJoin from 'url-join';

import { makeGoogleMapsStaticMapUrl, makeRequest } from '@/utils';
import { ROUTES } from '@/routes';
import { formatCurrency } from '@/utils/currency';
// import { getOpenHours } from '@/utils/dates';
import { useLang } from '@/hooks/useLang';
import { addLocale, getCountryCodeFromLang } from '@/utils/locales';
import { OrderPaymentStatus } from '@/consts/order';
import { Lang } from '@/types/locales';
import { LOCALES } from '@/i18nConfig';
import { cleanPhoneNumber } from '@/utils/phoneNumber';
import { FormattedGroup } from '@/utils/dates/types';

import {
  OrderDetails,
  UseOrderDetailsQRCodeReturnType,
  UseOrderPageQueryParamsReturnType,
} from './types';
import { CASH_AMOUNT_PRECISION, CRYPTO_AMOUNT_PRECISION } from './consts';
import { createTransferQrCodePayload } from './utils';

export const useOrderPageQueryParams = (): UseOrderPageQueryParamsReturnType => {
  const searchParams = useSearchParams();

  const orderId = searchParams.get('orderId');
  const atm = searchParams.get('atm');
  const phoneNumber = searchParams.get('phoneNumber');
  const cleanedPhoneNumber = cleanPhoneNumber(phoneNumber || '');

  return {
    orderId,
    atm,
    phoneNumber: cleanedPhoneNumber,
  };
};

export const useOrderDetailsQRCode = (): UseOrderDetailsQRCodeReturnType => {
  const router = useRouter();
  const { orderId, atm, phoneNumber } = useOrderPageQueryParams();
  const [simpleQrCodeRequested, setSimpleQrCodeRequested] = useState(false);
  const [paymentQrCodePayload, setPaymentQrCodePayload] = useState<string | null>(null);
  const lang = useLang();
  const countryCode = getCountryCodeFromLang(lang);

  const queryParams = qs.stringify(
    {
      orderId,
      phoneNumber,
      atm,
      countryCode,
    },
    {
      addQueryPrefix: true,
    },
  );

  const {
    data,
    error,
    isLoading: isOrderDetailsLoading,
  } = useSWR<OrderDetails>(
    [ROUTES.sellCryptoOrderDetails, queryParams],
    () => {
      const response = makeRequest<OrderDetails>(
        urlJoin(ROUTES.sellCryptoOrderDetails, queryParams),
      );

      return response;
    },
    {
      onSuccess: (result) => {
        const { cryptoAddress, cryptoAmount, orderStatus } = result;

        setPaymentQrCodePayload(
          simpleQrCodeRequested
            ? createTransferQrCodePayload(cryptoAddress)
            : createTransferQrCodePayload(cryptoAddress, cryptoAmount),
        );

        if (orderStatus !== OrderPaymentStatus.PaymentRequested) {
          const url = urlJoin(addLocale(ROUTES.preSellOrder, lang), queryParams);

          router.push(url);
        }
      },
      refreshInterval: (result) => {
        const { orderStatus } = result ?? {};

        if (orderStatus === OrderPaymentStatus.PaymentRequested) {
          //  Every 30 seconds
          return 1000 * 30;
        }

        return 0;
      },
    },
  );

  return useMemo(() => {
    if (!data || !orderId || !atm) {
      return {
        order: null,
        orderStatusProps: null,
        paymentQrCodePayload: null,
        isLoading: true,
        simpleQrCodeRequested,
      };
    }

    const {
      cryptoAddress,
      cryptoCurrency,
      cryptoAmount,
      cashCurrency,
      cashAmount,
      orderStatus,
      createdAt,
      location: { name, street, zip, country, gmapslink, latitude, longitude },
    } = data;

    const city = 'city' in data.location ? data.location.city : data.location?.area;

    const src = makeGoogleMapsStaticMapUrl(latitude, longitude, {
      markerColor: '0xffa853',
      size: '500x360',
      zoom: 15,
    });

    // TODO: Uncomment when openHours are available in the response
    // const openHours = getOpenHours(data.location);
    const openHours = [] as FormattedGroup[];

    return {
      order: data,
      orderStatusProps: {
        orderId,
        createdAt,
        currentAtmId: atm,
        openHours,
        locationName: name,
        address: `${street}, ${city}, ${zip}, ${country}`,
        googleHref: gmapslink,
        src,
        alt: 'Map',
        cash: `$${formatCurrency({
          amount: cashAmount * 100,
          currencySymbol: cashCurrency,
          precision: CASH_AMOUNT_PRECISION,
          separateCurrency: true,
          currencyPlacement: 'suffix',
        })}`,
        crypto: formatCurrency({
          amount: cryptoAmount,
          currencySymbol: cryptoCurrency.toUpperCase(),
          precision: CRYPTO_AMOUNT_PRECISION,
          separateCurrency: true,
          currencyPlacement: 'suffix',
          removeTrailingZeros: true,
        }),
        destination: cryptoAddress,
        status: orderStatus as OrderPaymentStatus,
      },
      getSimplePaymentQrCodePayload: () => {
        setSimpleQrCodeRequested(true);
        setPaymentQrCodePayload(createTransferQrCodePayload(cryptoAddress));
      },
      paymentQrCodePayload,
      simpleQrCodeRequested,
      isLoading: isOrderDetailsLoading,
      error,
    };
  }, [
    data,
    orderId,
    atm,
    paymentQrCodePayload,
    simpleQrCodeRequested,
    isOrderDetailsLoading,
    error,
  ]);
};

export const getCurrencyFromLang = (lang: Lang) => {
  switch (lang) {
    case LOCALES.EN_CA:
      return 'CAD';
    case LOCALES.EN_AU:
      return 'AUD';
    default:
      return 'CAD';
  }
};
