import { sendGTMEvent } from '@next/third-parties/google';
import { MappedObject } from '@/types/global';

import { Location } from '../types';
import { checkIfOpenNow, getOpenHoursString } from '../dates';
import { GTMOrderData, GTMSurveyData, GTMTransactionData } from './types';
import { ONLINE_SELL_CONFIRMED_EVENT } from './consts';

export const pushGTMEventForOrder = async (
  { atmId, locationName, address, openHours }: GTMOrderData,
  eventName: string,
): Promise<void> => {
  sendGTMEvent({
    event: eventName,
    atm_id: atmId,
    store_name: locationName,
    store_address: address,
    open_hours: openHours,
  });
};

export const pushGTMEventForATM = async (
  location: Location,
  eventName: string,
  additionalParams: MappedObject<string> = {},
): Promise<void> => {
  try {
    const { name, location: locationDetails, prices, id: atmId } = location;

    if (!prices) {
      return;
    }

    const { btcBuyPrice, btcSellPrice, ethBuyPrice, ltcBuyPrice } = prices ?? {};
    const { street, zip, country } = locationDetails;

    const address =
      locationDetails.country === 'Hong Kong'
        ? `${street}, ${locationDetails.district}, ${country}`
        : `${street}, ${locationDetails.city}, ${zip}, ${country}`;

    sendGTMEvent({
      event: eventName,
      BATM: atmId,
      store_name: name,
      store_address: address,
      open_now: checkIfOpenNow(locationDetails),
      hours: getOpenHoursString(locationDetails),
      BTC_buy_price: btcBuyPrice,
      BTC_sell_price: btcSellPrice,
      ETH_buy_price: ethBuyPrice,
      LTC_buy_price: ltcBuyPrice,
      ...additionalParams,
    });
  } catch (ex) {
    console.log(ex);
  }
};

export const pushGTMEvent = async (data: object): Promise<void> => {
  try {
    sendGTMEvent(data);
  } catch (ex) {
    console.log(ex);
  }
};

export const pushSurveyReviewGTMEvent = async (data: GTMSurveyData): Promise<void> =>
  pushGTMEvent(data);

export const pushTransactionGTMEvent = async (data: GTMTransactionData): Promise<void> =>
  pushGTMEvent({
    ...data,
    event: ONLINE_SELL_CONFIRMED_EVENT,
  });
