import Dinero from 'dinero.js';

export const roundNumber = (value: number, precision = 0): number =>
  Math.round((value + Number.EPSILON) * 10 ** precision) / 10 ** precision;

export const currencyPrecisionMap = (currencySymbol: string): number => {
  switch (currencySymbol) {
    case 'JPY':
      return 0;
    default:
      return 2;
  }
};

export const formatCurrency = ({
  amount,
  currencySymbol,
  format,
  separateCurrency = false,
  precision = 2,
  currencyPlacement = 'prefix',
  removeTrailingZeros = false,
}: {
  amount: number;
  currencySymbol: string;
  format?: string;
  separateCurrency?: boolean;
  precision?: number;
  currencyPlacement?: 'prefix' | 'suffix';
  removeTrailingZeros?: boolean;
}): string => {
  const integerAmount = Number.isInteger(amount)
    ? amount
    : (() => {
        const floatAsString = amount.toFixed(precision);

        return Math.floor(Number(floatAsString) * 10 ** precision);
      })();

  const dineroInstance = Dinero({
    amount: integerAmount,
    precision: !precision ? currencyPrecisionMap(currencySymbol) : precision,
  });

  const evaluatedFormat =
    format || (dineroInstance.hasSubUnits() ? '0,0.'.concat('0'.repeat(precision)) : '0,0');
  const formattedValue = (() => {
    const fVal = dineroInstance.toFormat(evaluatedFormat).replace(/-/gi, '');

    if (removeTrailingZeros) {
      return fVal.replace(/(\.\d*?[1-9])0+$/g, '$1');
    }

    return fVal;
  })();

  if (currencyPlacement === 'prefix') {
    return `${integerAmount < 0 ? '- ' : ''}${currencySymbol}${
      separateCurrency ? '\xa0' : ''
    }${formattedValue}`;
  }

  return `${integerAmount < 0 ? '- ' : ''}${formattedValue}${
    separateCurrency ? '\xa0' : ''
  }${currencySymbol}`;
};
