export const createTransferQrCodePayload = (cryptoAddress: string, amount?: number): string => {
  // TODO: change it when we have more crypto currencies
  const base = `bitcoin:${cryptoAddress}`;

  if (typeof amount !== 'number') {
    return base;
  }

  return `${base}?amount=${amount}`;
};
